import Component from "../../../base/Component";

export default class ButtonFilter extends Component {
  constructor(props) {
      super(props)
  }

  init() {
    document.addEventListener('DOMContentLoaded', () => {
      const currentURL = new URL(window.location.href);
      const typeParam = currentURL.searchParams.get('type');

      if (typeParam) {

        const activeElement = document.querySelector(`[filter-button][data-value="${typeParam}"]`);
        if (activeElement) {
          activeElement.classList.remove('transparent');
          activeElement.classList.add('active');
        }
      }
    });
    
    document.addEventListener('click', (e) => {
      if (e.target.closest('[filter-button]')) {
        e.preventDefault();
        
        const filterType = e.target.dataset.value;
        const currentURL = new URL(window.location.href);
        
        const categoryParam = currentURL.searchParams.get('category');
        const typeParam = currentURL.searchParams.get('type');
        
        if (categoryParam || typeParam || categoryParam && typeParam) {
          currentURL.searchParams.set('category', '');
          currentURL.searchParams.set('type', filterType);
        } else {
          currentURL.searchParams.set('type', filterType);
        }
        
        window.location.href = currentURL.toString();
      }
    });    
  }
}

